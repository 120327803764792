import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Nasze usługi | Usługi Myjnia
			</title>
			<meta name={"description"} content={"Crafting Perfection on Wheels - Każda usługa, znak doskonałości"} />
			<meta property={"og:title"} content={"Nasze usługi | Usługi Myjnia"} />
			<meta property={"og:description"} content={"Crafting Perfection on Wheels - Każda usługa, znak doskonałości"} />
			<meta property={"og:image"} content={"https://blivits.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://blivits.com/img/car-service.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blivits.com/img/car-service.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blivits.com/img/car-service.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blivits.com/img/car-service.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blivits.com/img/car-service.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blivits.com/img/car-service.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" /><Text
					
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 50px 0px"
				>
					Nasze usługi
				</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Myjnia zapewnia kompleksowy zakres usług, które zaspokajają każdy aspekt estetycznych i ochronnych potrzeb pojazdu. Nasz zespół ekspertów jest zaangażowany w dostarczanie jakości i precyzji w każdej oferowanej przez nas usłudze.
			</Text>
		</Section>
		<Section padding="20px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Myjnia szczegółowa
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						- Myjnia Premium: Rozległy proces czyszczenia przy użyciu najwyższej jakości, ekologicznych produktów, aby pozostawić samochód nieskazitelny i lśniący.
						<br/><br/>
- Detailing wnętrza: Skrupulatna dbałość o wnętrze samochodu, zapewniająca czyszczenie, kondycjonowanie i ochronę każdej powierzchni.

						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://blivits.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://blivits.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Zaawansowane usługi detailingowe
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						- Korekta lakieru: Usuwanie zawirowań i zadrapań w celu przywrócenia oryginalnego, nieskazitelnego wykończenia lakieru.
						<br/><br/>
- Detailing komory silnika: Dokładne czyszczenie i pielęgnacja komory silnika, aby wyglądała jak nowa.

						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Folia ochronna lakieru (PPF)
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						- Pełna ochrona karoserii: Specjalistyczna aplikacja najwyższej jakości PPF w celu ochrony lakieru samochodu przed zadrapaniami, odpryskami i uszkodzeniami środowiskowymi.
						<br/><br/>
- Indywidualne opcje PPF: Dopasowane rozwiązania PPF chroniące określone obszary pojazdu podatne na zużycie.

						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://blivits.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://blivits.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Usługi specjalistyczne
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						- Renowacja reflektorów: Przywraca jasność reflektorom, poprawiając zarówno wygląd, jak i bezpieczeństwo pojazdu.
						<br/><br/>
Ochrona kół: Szczegółowe czyszczenie i nakładanie powłok ochronnych w celu utrzymania kół w nieskazitelnym stanie i ochrony przed pyłem hamulcowym i korozją.

						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Aby uzyskać więcej informacji na temat naszego pełnego zakresu usług lub umówić się na wizytę:
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					- Odwiedź nas: Przekonaj się na własne oczy, z jaką dbałością i szczegółowością podchodzimy do każdego pojazdu.
					<br/><br/>
- Zadzwoń lub wyślij e-mail: Nasz zespół jest gotowy odpowiedzieć na Twoje pytania i pomóc Ci wybrać idealną usługę dla Twojego samochodu.

				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://blivits.com/img/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});